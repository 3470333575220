/* eslint-disable jsx-a11y/heading-has-content */
import { useState } from "react";
import { Col, Container, Image, Row, Modal } from "react-bootstrap";
import Countdown from "react-countdown";
import Header from "./components/Header";
import Chain from "./components/Chain";
import Footer from "./components/Footer";
import VideoSection from "./components/VideoSection";
import { withTranslation } from "react-i18next";
import TopSlider from "./components/TopSlider";
import ReactPlayer from "react-player";

function Home(props) {
  const { t } = props;

  const [current, setCurrent] = useState(1);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    window.open("https://www.galler.io/en/launchpad/11", "_blank");
  };

  var date = new Date(new Date().getTime() + 2 * 60 * 60 * 24 * 1000);
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return "";
    } else {
      // Render a countdown
      return (
        <>
          <div className="timer-bg d-none"></div>
          <div className="timer-container d-none">
            <div className="time-stack">
              <span className="time">{hours}</span>
              <p className="time-txt">Hours</p>
            </div>
            <div className="time-stack">
              <span className="time">{minutes}</span>
              <p className="time-txt">Minutes</p>
            </div>
            <div className="time-stack">
              <span className="time">{seconds}</span>
              <p className="time-txt">Seconds</p>
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <div>
      <div className="top-content" id="top">
        {/* <div className="video-bg">
          <video autoPlay={true} loop={true} id="myVideo">
            <source src="/bg.mp4" type="video/mp4" />
          </video>
        </div> */}
        <Header handleShow={handleShow} />
        <Container className="py-md-5">
          <Row>
            <Col md={6} className="py-md-5 ">
              <div className="intro">
                <h1 className="title1" id="top-title1">
                  {t("homepage.welcome")}
                </h1>
                <h1
                  className="title2"
                  id="top-title2"
                  style={{ animationDelay: "1s" }}
                >
                  AZNVERSE
                  <span className="small">(Asian-verse)</span>
                </h1>

                <p className="mt-md-4">{t("homepage.description")}</p>
              </div>
              <div className="rect-border mt-5 ps-3">
                <div className="flex">
                  <div className="feat">
                    <p className="p-0">{t("homepage.culture")}</p>
                  </div>
                  <div className="feat border-left">
                    <p className="p-0">{t("homepage.community")}</p>
                  </div>
                  <div className="feat border-left">
                    <p className="p-0">{t("homepage.club")}!</p>
                  </div>
                </div>
                <div>
                  <button className="btn btn-rect" onClick={handleShow}>
                    Mint Now
                  </button>
                </div>
              </div>
              <a
                href="https://discord.gg/cQAEcnzHFn"
                className="channel"
                target="_blank"
                rel="noreferrer"
              >
                <Image src="/discord.png" fluid />{" "}
                {t("homepage.visionsection.discord")}
              </a>
              <div className="arrow-container">
                <a href="#milestone">
                  <Image className="arrow-down" src="/arrow_down.svg" fluid />
                </a>
              </div>
            </Col>
            <Col md={6} className="top-img-container">
              {/* <Image className="top-img" src="/fifth.png" fluid /> */}
              <TopSlider />
              {/* <div className="wave__container">
                <div className="wave__circle"></div>
                <div className="wave__circle"></div>
                <div className="wave__circle"></div>
              </div> */}
            </Col>
          </Row>
        </Container>
        <div className="svg-border">
          <svg
            viewBox="0 0 1280 16"
            preserveAspectRatio="none"
            color="text"
            width="20px"
            xmlns="http://www.w3.org/2000/svg"
            className="sc-bdvvtL wPKGk"
          >
            <path
              d="M0 16V0c159.43 9.206 387.163 14.966 640 14.966S1120.57 9.206 1280 0v16H0z"
              fill="url(#squad_header_bottom_wave1)"
            ></path>
            <defs>
              <linearGradient
                id="squad_header_bottom_wave1"
                x1="174.5"
                y1="15.999"
                x2="1233.5"
                y2="16"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#8051d6"></stop>
                <stop offset="1" stopColor="#8051d6"></stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      {/* <div
        id="milestone"
        className="pt-5 pb-md-5 other-content milestone d-none"
      >
        <Container className="py-5">
          <Row>
            <Col md={12} className="pt-md-5">
              <FadeInSection>
                <h1 className="title3 pt-5 mt-md-5 align-center">Milestones</h1>
                <div className="border-blck my-3 mb-4"></div>
              </FadeInSection>
            </Col>
          </Row>
          <div className="row text-center">
            <div className="col-md-3" data-aos="fade-right">
              <div className="counter">
                <h2
                  className="timer count-title count-number"
                  data-to="3126456"
                  data-speed="1500"
                ></h2>
                <p className="count-text ">Registered Users</p>
              </div>
            </div>
            <div className="col-md-3" data-aos="fade-right">
              <div className="counter">
                <h2 className="count-title">
                  $
                  <span
                    className="timer count-number"
                    data-to="261587017"
                    data-speed="1500"
                  ></span>
                </h2>
                <p className="count-text ">NFT Trade Volume</p>
              </div>
            </div>
            <div className="col-md-3" data-aos="fade-left">
              <div className="counter">
                <h2
                  className="timer count-title count-number"
                  data-to="1143954"
                  data-speed="1500"
                ></h2>

                <p className="count-text ">NFTs Minted</p>
              </div>
            </div>
            <div className="col-md-3" data-aos="fade-left">
              <div className="counter">
                <h2 className="count-title">
                  $
                  <span
                    className="timer count-number"
                    data-to="620000"
                    data-speed="1500"
                  ></span>
                </h2>
                <p className="count-text ">Most Expensive NFT Sold</p>
              </div>
            </div>
          </div>
        </Container>
      </div> */}
      <div className="pt-5 pb-md-5 other-content second-sec">
        <Container className="py-5">
          <Row id="milestone">
            <Col md={1}></Col>
            <Col md={10} className="pt-md-5">
              {/* <FadeInSection> */}
              <h1
                className="title3 pt-5 mt-md-5 align-center"
                data-aos="fade-right"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                {t("homepage.firstsection.title")}
              </h1>
              <div
                className="border-blck my-3 mb-4"
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="500"
              ></div>
              <p
                className="text-center"
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                {t("homepage.firstsection.description")}
              </p>
              {/* </FadeInSection> */}
            </Col>
            <Col md={1}></Col>
          </Row>
          <Row className="mt-4 feature text-center">
            <Col data-aos="fade-right">
              {/* <FadeInSection> */}
              <Image src="/1.png" fluid />
              <p>{t("homepage.firstsection.firstImg")}</p>
              {/* </FadeInSection> */}
            </Col>
            <Col data-aos="fade-right">
              {/* <FadeInSection> */}
              <Image src="/2.png" fluid style={{ animationDelay: "0.15s" }} />
              <p>{t("homepage.firstsection.secondImg")}</p>
              {/* </FadeInSection> */}
            </Col>
            <Col data-aos="zoom-in">
              {/* <FadeInSection> */}
              <Image src="/3.png" fluid style={{ animationDelay: "0.3s" }} />
              <p>{t("homepage.firstsection.thirdImg")}</p>
              {/* </FadeInSection> */}
            </Col>
            <Col data-aos="fade-left">
              {/* <FadeInSection> */}
              <Image src="/4.png" fluid style={{ animationDelay: "0.45s" }} />
              <p>{t("homepage.firstsection.fourthImg")}</p>
              {/* </FadeInSection> */}
            </Col>
            <Col data-aos="fade-left">
              {/* <FadeInSection> */}
              <Image src="/5.png" fluid style={{ animationDelay: "0.6s" }} />
              <p>{t("homepage.firstsection.fifthImg")}</p>
              {/* </FadeInSection> */}
            </Col>
          </Row>
        </Container>
        <div className="svg-border">
          <svg
            viewBox="0 0 1280 16"
            preserveAspectRatio="none"
            color="text"
            width="20px"
            xmlns="http://www.w3.org/2000/svg"
            className="sc-bdvvtL wPKGk"
          >
            <path
              d="M0 16V0c159.43 9.206 387.163 14.966 640 14.966S1120.57 9.206 1280 0v16H0z"
              fill="url(#squad_header_bottom_wave)"
            ></path>
            <defs>
              <linearGradient
                id="squad_header_bottom_wave"
                x1="174.5"
                y1="15.999"
                x2="1233.5"
                y2="16"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#1e593d"></stop>
                <stop offset="1" stopColor="#1e593d"></stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div
        id="vision"
        className="py-md-5 other-content vision position-relative"
      >
        <Container className="py-md-5">
          <Row>
            <Col md={6} className="pe-5  left">
              <div className="sticky-wrapper sticky-top">
                <Image src="/fourth.png" fluid className="img-fixed" />
                <Countdown date={date} renderer={renderer} />
              </div>
            </Col>
            <Col md={6} className="right">
              <div className="intro-block ">
                <h1
                  className="title3 "
                  data-aos="fade-left"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-duration="1000"
                  data-aos-delay="500"
                >
                  {t("homepage.secondsection.title")}{" "}
                  <span className="small">
                    <a
                      href="https://bit.ly/3nDcak5"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("homepage.secondsection.detailedRoadmap")}
                    </a>
                  </span>
                </h1>
                <p
                  data-aos="fade-left"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-duration="1000"
                  data-aos-delay="500"
                >
                  {t("homepage.secondsection.description")}
                </p>
                <div>
                  <ul className="timeline">
                    <li className="timeline-item " data-aos="fade-up">
                      <div className="timeline-line">
                        <h1 className="title ">1</h1>
                      </div>
                      <div className="timeline-content">
                        <div className="timeline-inner">
                          <h4>{t("homepage.secondsection.firstitem.title")}</h4>
                        </div>
                      </div>
                    </li>
                    <li className="timeline-item " data-aos="fade-up">
                      <div className="timeline-line">
                        <h1 className="title ">2</h1>
                      </div>
                      <div className="timeline-content">
                        <div className="timeline-inner">
                          <h4>
                            {t("homepage.secondsection.seconditem.title")}
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="timeline-item " data-aos="fade-up">
                      <div className="timeline-line">
                        <h1 className="title ">3</h1>
                      </div>
                      <div className="timeline-content">
                        <div className="timeline-inner">
                          <h4>{t("homepage.secondsection.thirditem.title")}</h4>
                        </div>
                      </div>
                    </li>
                    <li className="timeline-item " data-aos="fade-up">
                      <div className="timeline-line">
                        <h1 className="title ">4</h1>
                      </div>
                      <div className="timeline-content">
                        <div className="timeline-inner">
                          <h4>
                            {t("homepage.secondsection.fourthitem.title")}
                          </h4>
                          <p>
                            {t("homepage.secondsection.fourthitem.description")}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="timeline-item " data-aos="fade-up">
                      <div className="timeline-line">
                        <h1 className="title ">5</h1>
                      </div>
                      <div className="timeline-content">
                        <div className="timeline-inner">
                          <h4>{t("homepage.secondsection.fiveitem.title")}</h4>
                          <p>
                            {t("homepage.secondsection.fiveitem.description")}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="timeline-item " data-aos="fade-up">
                      <div className="timeline-line">
                        <h1 className="title ">6</h1>
                      </div>
                      <div className="timeline-content">
                        <div className="timeline-inner">
                          <h4>{t("homepage.secondsection.sixitem.title")}</h4>
                          <p>
                            {t("homepage.secondsection.sixitem.description")}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="svg-border">
          <svg
            viewBox="0 0 1280 16"
            preserveAspectRatio="none"
            color="text"
            width="20px"
            xmlns="http://www.w3.org/2000/svg"
            className="sc-bdvvtL wPKGk"
          >
            <path
              d="M0 16V0c159.43 9.206 387.163 14.966 640 14.966S1120.57 9.206 1280 0v16H0z"
              fill="url(#squad_header_bottom_wave2)"
            ></path>
            <defs>
              <linearGradient
                id="squad_header_bottom_wave2"
                x1="174.5"
                y1="15.999"
                x2="1233.5"
                y2="16"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#206344"></stop>
                <stop offset="1" stopColor="#206344"></stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div className="video-sec other-content">
        <Container className="py-5 mt-md-4">
          <Row>
            <Col md={12} data-aos="fade-in">
              <VideoSection />
            </Col>
          </Row>
        </Container>
        <div className="svg-border">
          <svg
            viewBox="0 0 1280 16"
            preserveAspectRatio="none"
            color="text"
            width="20px"
            xmlns="http://www.w3.org/2000/svg"
            className="sc-bdvvtL wPKGk"
          >
            <path
              d="M0 16V0c159.43 9.206 387.163 14.966 640 14.966S1120.57 9.206 1280 0v16H0z"
              fill="url(#squad_header_bottom_wave_video)"
            ></path>
            <defs>
              <linearGradient
                id="squad_header_bottom_wave_video"
                x1="174.5"
                y1="15.999"
                x2="1233.5"
                y2="16"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#8051d6"></stop>
                <stop offset="1" stopColor="#8051d6"></stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div id="momo" className="momo other-content">
        <Container className="py-5 mt-md-4">
          <Row>
            <Col md={1}></Col>
            <Col md={10}>
              <h1 className="title3 align-center" data-aos="fade-up">
                AZNverse + Tokoverse
              </h1>
              <div className="border-blck my-3 mb-4" data-aos="fade-up"></div>
              <div className="cnt-bg" data-aos="fade-up">
                <div className="inner">
                  <p className="align-center">
                    {t("homepage.aznsection.line1")}
                  </p>
                  <p className="align-center">
                    {t("homepage.aznsection.line2")}
                  </p>
                  <p className="align-center">
                    {t("homepage.aznsection.line3")}
                  </p>
                </div>
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>

          <div className="py-5 mb-md-4">
            <Chain />
          </div>
        </Container>
        <div className="svg-border">
          <svg
            viewBox="0 0 1280 16"
            preserveAspectRatio="none"
            color="text"
            width="20px"
            xmlns="http://www.w3.org/2000/svg"
            className="sc-bdvvtL wPKGk"
          >
            <path
              d="M0 16V0c159.43 9.206 387.163 14.966 640 14.966S1120.57 9.206 1280 0v16H0z"
              fill="url(#squad_header_bottom_wave)"
            ></path>
            <defs>
              <linearGradient
                id="squad_header_bottom_wave"
                x1="174.5"
                y1="15.999"
                x2="1233.5"
                y2="16"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#1e593d"></stop>
                <stop offset="1" stopColor="#1e593d"></stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div id="nft" className="pb-md-5 other-content ">
        <Container className="py-5">
          <Row>
            <Col md={6} className="ps-4 py-md-5 position-relative">
              <h1
                className="title3 vision"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                {t("homepage.visionsection.title")}
              </h1>
              <div className="position-absolute">
                <div className="left-border-blck" data-aos="fade-up"></div>
              </div>
              <div className="header-txt">
                <ul>
                  <li
                    className={current === 1 ? "active" : ""}
                    onClick={() => setCurrent(1)}
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div className="title">
                      {t("homepage.visionsection.line1.title")}
                    </div>
                    <div className="li-active"></div>
                  </li>
                  <li
                    className={current === 2 ? "active" : ""}
                    onClick={() => setCurrent(2)}
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div className="title">
                      {t("homepage.visionsection.line2.title")}
                    </div>
                    <div className="li-active"></div>
                  </li>
                  <li
                    className={current === 3 ? "active" : ""}
                    onClick={() => setCurrent(3)}
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div className="title">
                      {t("homepage.visionsection.line3.title")}
                    </div>
                    <div className="li-active"></div>
                  </li>
                </ul>
              </div>
              <div className="content-txt">
                {current === 1 && (
                  <div data-aos="fade-up" data-aos-delay="100">
                    <h1 className="title pb-4 ">
                      {t("homepage.visionsection.line1.title")}
                    </h1>
                    <h5>{t("homepage.visionsection.line1.description")}</h5>
                    <a
                      href="https://discord.gg/cQAEcnzHFn"
                      className="channel"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src="/discord.png" fluid />{" "}
                      {t("homepage.visionsection.discord")}
                    </a>
                  </div>
                )}
                {current === 2 && (
                  <div data-aos="fade-up" data-aos-delay="100">
                    <h1 className="title pb-4 ">
                      {t("homepage.visionsection.line2.title")}
                    </h1>
                    <h5>{t("homepage.visionsection.line2.description")}</h5>
                    <a
                      href="https://discord.gg/cQAEcnzHFn"
                      className="channel"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src="/discord.png" fluid />{" "}
                      {t("homepage.visionsection.discord")}
                    </a>
                  </div>
                )}
                {current === 3 && (
                  <div data-aos="fade-up" data-aos-delay="100">
                    <h1 className="title third pb-4 ">
                      {t("homepage.visionsection.line3.title")}
                    </h1>
                    <h5>{t("homepage.visionsection.line3.description")}</h5>
                    <a
                      href="https://discord.gg/cQAEcnzHFn"
                      className="channel"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src="/discord.png" fluid />{" "}
                      {t("homepage.visionsection.discord")}
                    </a>
                  </div>
                )}
              </div>
            </Col>
            <Col md={6} className="py-md-5">
              {/* {current === 1 && (
                <div
                  className=" text-end nft-img"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <Image className="bottom-img" src="/first.png" fluid />
                </div>
              )}
              {current === 2 && (
                <div
                  className=" text-end nft-img"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <Image className="bottom-img" src="/second.png" fluid />
                </div>
              )}
              {current === 3 && (
                <div
                  className=" text-end nft-img"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <Image className="bottom-img" src="/third.png" fluid />
                </div>
              )} */}
              <ReactPlayer
                url="/bg.mp4"
                width="100% "
                height="100%"
                className="bottom-video"
                controls={false}
                volume={0}
                muted={true}
                playing={true}
                loop
                onError={(e) => console.log("onError", e)}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer handleShow={handleShow} {...props} />
      <Modal
        show={show}
        className="cooming"
        centered
        backdrop={true}
        onHide={handleClose}
      >
        <div className="modal-inner">
          <Modal.Header closeButton>
            <Modal.Title>
              <h1>Coming Soon</h1>
            </Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
        </div>
      </Modal>
    </div>
  );
}

export default withTranslation()(Home);
