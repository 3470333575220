import { Col, Container, Image, Nav, Row } from "react-bootstrap";

const Footer = ({ handleShow, t }) => {
  return (
    <footer className="other-content footer">
      <Container className="pt-5 pb-2">
        <Row>
          <Col md={6}>
            <div className="social">
              <a
                href="https://discord.gg/cQAEcnzHFn"
                target="_blank"
                rel="noreferrer"
              >
                <Image src="/discord.png" fluid />
              </a>
              <a
                href="https://twitter.com/AzNverse"
                target="_blank"
                rel="noreferrer"
              >
                <Image src="/twiter.png" fluid />
              </a>
            </div>
            <Nav className="footer-nav pt-4">
              <Nav.Item>
                <Nav.Link href="#top">{t("Home")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="link-2" onClick={handleShow}>
                  NFTs
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="link-3">{t("Community")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="link-4">{t("Docs")}</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col
            md={6}
            className="footer-logo-blck"
            style={{ textAlign: "right", alignSelf: "center" }}
          >
            <Image
              className="footer-logo"
              src="/AZN-Logo-white.png"
              width="150"
              fluid
            />
          </Col>
          <Col md={6} className="mt-md-5">
            <p className="copyright">
              {t("Copyright")} © 2022 AZNverse. {t("All rights reserved")}.
            </p>
          </Col>
          <Col md={6} className="mt-md-5" style={{ textAlign: "right" }}>
            <Nav className="foo">
              <Nav.Item>
                <Nav.Link eventKey="link-1">{t("Terms of Service")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="link-2" className="border-left">
                  {t("Privacy Policy")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="link-3" className="border-left">
                  {t("Contact us")}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
export default Footer;