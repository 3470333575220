import "./App.css";
import Home from "./views/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPlayer from "react-player";
import { Component } from "react";
import { isMobile } from "react-device-detect";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pip: false,
      playing: false,
      controls: false,
      light: false,
      volume: 0.15,
      muted: true,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      show: false,
      fadeout: false,
    };
  }

  handlePlay = () => {
    console.log("onPlay");
    // this.player.seekTo(parseFloat(0.2));
    // this.player.play();
    //console.log(this.player.getInternalPlayer().play());
    this.setState({ playing: true });
  };

  ref = (player) => {
    this.player = player;
  };
  componentDidMount() {
    document.body.classList.add("sidebar-open");
    setTimeout(() => {
      this.btn.click();
    }, 100);
    // if (isMobile && isSafari) {
    //   setTimeout(() => {
    //     document.body.classList.remove("sidebar-open");
    //     this.setState({ fadeout: true, playing: false });
    //     document.getElementById("top-title1").classList.add("typing");
    //   }, 5000);
    //   setTimeout(() => {
    //     document.getElementById("top-title2").classList.add("typing");
    //   }, 6000);
    // }
  }
  handleProgress = (state) => {
    if (state.playedSeconds > 5) {
      document.body.classList.remove("sidebar-open");
      this.setState({ fadeout: true });
    }
    if (state.playedSeconds > 5.3) {
      document.getElementById("top-title1").classList.add("typing");
      setTimeout(() => {
        document.getElementById("top-title2").classList.add("typing");
      }, 1000);
    }
  };
  handleShow() {
    document.body.classList.remove("sidebar-open");
    this.setState({ fadeout: true, playing: false });
    document.getElementById("top-title1").classList.add("typing");
    setTimeout(() => {
      document.getElementById("top-title2").classList.add("typing");
    }, 1000);
  }
  render() {
    const { playing, controls, volume, muted, fadeout } = this.state;

    return (
      <>
        <button
          ref={(btn) => (this.btn = btn)}
          onClick={() => {
            this.player.getInternalPlayer().play();
          }}
          style={{ display: "none" }}
        >
          Play
        </button>
        <div className={`video-container ${fadeout ? "video-end" : ""}`}>
          <ReactPlayer
            ref={(player) => {
              this.player = player;
            }}
            url={isMobile ? "/AZN-mobile.mp4" : "/AZN.mp4"}
            width="100% "
            height="100%"
            className={`intro-video`}
            controls={controls}
            volume={volume}
            muted={muted}
            playing={playing}
            onReady={() => this.handlePlay()}
            playsinline
            onError={(e) => console.log("onError", e)}
            onProgress={this.handleProgress}
            onDuration={this.handleDuration}
          />
          <button
            className="btn btn-rect landing-btn"
            onClick={() => this.handleShow()}
            style={{
              position: "absolute",
              bottom: "70px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            Enter AZNverse
          </button>
        </div>
        <Home />
        <ToastContainer />
      </>
    );
  }
}

export default App;
