/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Image } from "react-bootstrap";

const handleDragStart = (e) => e.preventDefault();

const items = [
  <Image
    fluid
    src="/fifth.png"
    onDragStart={handleDragStart}
    role="presentation"
  />,
  <Image
    fluid
    src="/first.png"
    onDragStart={handleDragStart}
    role="presentation"
  />,

  <Image
    fluid
    src="/NFT-IMG-01.png"
    onDragStart={handleDragStart}
    role="presentation"
  />,
  <Image
    fluid
    src="/NFT-IMG-02.png"
    onDragStart={handleDragStart}
    role="presentation"
  />,
  <Image
    fluid
    src="/NFT-IMG-03.png"
    onDragStart={handleDragStart}
    role="presentation"
  />,
  <Image
    fluid
    src="/NFT-IMG-04.png"
    onDragStart={handleDragStart}
    role="presentation"
  />,
  <Image
    fluid
    src="/NFT-IMG-05.png"
    onDragStart={handleDragStart}
    role="presentation"
  />,
];

const TopSlider = () => {
  return (
    <div className="top-img">
      <AliceCarousel
        autoPlay
        autoPlayStrategy="none"
        autoPlayInterval={300}
        // animationDuration={2000}
        animationType="fadeout"
        infinite
        touchTracking={false}
        disableDotsControls
        disableButtonsControls
        items={items}
      />
    </div>
  );
};
export default TopSlider;
