import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

function VideoSection(props) {
  const [playing, setPlaying] = useState(false);
  const domRef = useRef(null);

  useEffect(() => {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.75,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        console.log(entry);
        setPlaying(entry.isIntersecting);
      });
    }, options);
    observer.observe(domRef.current);
  }, []);
  return (
    <div ref={domRef}>
      <ReactPlayer
        url="/AZN-Launch.mp4"
        width="100% "
        height="100%"
        className="promo-video "
        controls={true}
        volume={0.1}
        muted={true}
        playing={playing}
        onError={(e) => console.log("onError", e)}
      />
    </div>
  );
}

export default VideoSection;
