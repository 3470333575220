import { useState } from "react";
import { Col, Container, Image, Nav, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./index.css";
// import { toast } from "react-toastify";

function Header({ handleShow }) {
  const [open, setOpen] = useState(false);
  const [t, i18n] = useTranslation();
  const toggleMenuSidebar = () => {
    if (open) {
      document.body.classList.remove("sidebar-open");
    } else {
      document.body.classList.add("sidebar-open");
    }
    setOpen(!open);
  };
  const closeSidebar = () => {
    document.body.classList.remove("sidebar-open");
    setOpen(!open);
  };

  return (
    <div className={`header ${open ? "open" : ""}`}>
      <Container className="">
        <Row className="align-items-center">
          <Col md={2} xs={5}>
            <Image className="logo" src="/AZN-Logo-white.png" fluid />
          </Col>
          <Col md={10} xs={7}>
            <div className="mobile-show navbar-dark">
              <Nav className="">
                {/* <Nav.Item className="border-left1">
                  <Nav.Link eventKey="link-4">
                    <button className="btn btn-rect" onClick={handleShow}>
                      Mint Now
                    </button>
                  </Nav.Link>
                </Nav.Item> */}
                <Nav.Item className="lng-container">
                  <button className="lng-btn">
                    <svg
                      viewBox="0 0 24 24"
                      color="textSubtle"
                      width="24px"
                      xmlns="http://www.w3.org/2000/svg"
                      className="lng"
                    >
                      <path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM18.92 8H15.97C15.65 6.75 15.19 5.55 14.59 4.44C16.43 5.07 17.96 6.35 18.92 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4.26 14C4.1 13.36 4 12.69 4 12C4 11.31 4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12C7.5 12.68 7.56 13.34 7.64 14H4.26ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM8.03 8H5.08C6.04 6.34 7.57 5.07 9.41 4.44C8.81 5.55 8.35 6.75 8.03 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM14.34 14H9.66C9.57 13.34 9.5 12.68 9.5 12C9.5 11.32 9.57 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12C14.5 12.68 14.43 13.34 14.34 14ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.36 14C16.44 13.34 16.5 12.68 16.5 12C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12C20 12.69 19.9 13.36 19.74 14H16.36Z"></path>
                    </svg>
                  </button>
                  <ul className="lang-dropdown">
                    <li>
                      <button
                        className="lng-btn"
                        onClick={() => i18n.changeLanguage("en")}
                      >
                        <Image className="" src="/usa.png" fluid />
                        EN
                      </button>
                    </li>
                    <li>
                      <button
                        className="lng-btn"
                        onClick={() => i18n.changeLanguage("id")}
                      >
                        <Image className="" src="/indonesia.png" fluid />
                        ID
                      </button>
                    </li>
                  </ul>
                </Nav.Item>
              </Nav>
              <button
                className="navbar-toggler"
                type="button"
                onClick={() => {
                  toggleMenuSidebar();
                }}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>

            <Nav className="justify-content-end py-2 mobile-hide align-items-center">
              <Nav.Item>
                <Nav.Link
                  href="#milestone"
                  eventKey="link-1"
                  data-text={t("Home")}
                >
                  AZN Cuan Club
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#vision" eventKey="link-2">
                  RoadMap
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#momo" eventKey="link-3">
                  AZNverse
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="border-right">
                <Nav.Link href="#nft" eventKey="link-4">
                  NFT Creator
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="link-4">
                  <button className="btn btn-rect" onClick={handleShow}>
                    Mint Now
                  </button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="lng-container">
                <button className="lng-btn">
                  <svg
                    viewBox="0 0 24 24"
                    color="textSubtle"
                    width="24px"
                    xmlns="http://www.w3.org/2000/svg"
                    className="lng"
                  >
                    <path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM18.92 8H15.97C15.65 6.75 15.19 5.55 14.59 4.44C16.43 5.07 17.96 6.35 18.92 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4.26 14C4.1 13.36 4 12.69 4 12C4 11.31 4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12C7.5 12.68 7.56 13.34 7.64 14H4.26ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM8.03 8H5.08C6.04 6.34 7.57 5.07 9.41 4.44C8.81 5.55 8.35 6.75 8.03 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM14.34 14H9.66C9.57 13.34 9.5 12.68 9.5 12C9.5 11.32 9.57 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12C14.5 12.68 14.43 13.34 14.34 14ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.36 14C16.44 13.34 16.5 12.68 16.5 12C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12C20 12.69 19.9 13.36 19.74 14H16.36Z"></path>
                  </svg>
                </button>
                <ul className="lang-dropdown">
                  <li>
                    <button
                      className="lng-btn"
                      onClick={() => i18n.changeLanguage("en")}
                    >
                      <Image className="" src="/usa.png" fluid />
                      EN
                    </button>
                  </li>
                  <li>
                    <button
                      className="lng-btn"
                      onClick={() => i18n.changeLanguage("id")}
                    >
                      <Image className="" src="/indonesia.png" fluid />
                      ID
                    </button>
                  </li>
                </ul>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </Container>
      <div className="mobile-bg"></div>
      <div className="sidebar-menu">
        <Nav className=" py-2 flex-column">
          <Nav.Item>
            <Nav.Link
              href="#milestone"
              onClick={() => closeSidebar()}
              eventKey="link-1"
            >
              AZN Cuan Club
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="#vision"
              onClick={() => closeSidebar()}
              eventKey="link-2"
            >
              RoadMap
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="#momo"
              onClick={() => closeSidebar()}
              eventKey="link-3"
            >
              AZNverse
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="#nft"
              onClick={() => closeSidebar()}
              eventKey="link-4"
            >
              NFT Creator
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    </div>
  );
}

export default Header;
